/* eslint-disable max-lines-per-function */
import React, { useMemo } from 'react'
import { useStore } from 'react-context-hook'
import SubRequestBadge from '/src/ui/domain/requests/sub_request_badge'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import I18n from '/src/utils/translations'
import useRequestsPinned from '/src/hooks/requests_pinned'
import { IsEqualOperator } from '/src/models/concerns/filter_operators'
import { isEmpty } from '/src/utils/object'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

/**
 * Fetch a request from a given dpmsid and set filter, gridTitle and label
 * This custom hook will just fetch a request by its id.
 *
 * @param requestId
 * @return {filter, gridTitle, labels}
 */
export default function useRequestGridFilter({ route, templateId, parent, modelName }) {
  const { selectedRequests, unpinRequests, isRequestsFilled, isFilterActive } = useRequestsPinned(false, modelName)
  const [disciplines] = useStore('disciplines')

  const filter = useMemo(() => {
    let column = parent ? `${parent}][request_id` : 'request_id'
    if (route === 'requests') column = 'id'

    if (modelName === 'request') {
      const value =
        selectedRequests && !isEmpty(selectedRequests)
          ? Object.values(selectedRequests)
              .map(({ id }) => id)
              .join(',')
          : '0'
      return isFilterActive
        ? [
            {
              type: 'where',
              column,
              operator: IsEqualOperator,
              value
            }
          ]
        : []
    }

    return selectedRequests && !isEmpty(selectedRequests)
      ? [
          {
            type: 'where',
            column,
            operator: IsEqualOperator,
            value: Object.values(selectedRequests)
              .map(({ id }) => id)
              .join(',')
          }
        ]
      : []
  }, [selectedRequests, parent, templateId, isFilterActive])

  const gridTitle = I18n.t('grid.all')

  const labels = useMemo(() => {
    if (!isRequestsFilled()) return []

    return Object.values(selectedRequests).map((request) => (
      <CustomTooltip position="bottom" parentTitle key={request.id}>
        <span title={request.comments}>
          <LabelFilter
            text={`${request.reason} - ${
              request.discipline ? request.discipline : disciplines[request.discipline_id]?.description
            }`}
            classes={request.parent_id ? 'subrequest-filtered' : ''}
            icon={request.parent_id ? <SubRequestBadge show /> : null}
            onClick={() => unpinRequests([request.id])}
            suppressLink
          />
        </span>
      </CustomTooltip>
    ))
  }, [isRequestsFilled, selectedRequests, route, templateId])

  return {
    filter,
    gridTitle,
    labels
  }
}
