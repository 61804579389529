import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useBeforeUnload from '/src/hooks/before_unload'
import useTemplateId from '/src/hooks/template_id'
import EditableGridLoader from '/src/ui/core/grid/editable/editable_grid_loader'
import EditableGrid from '/src/ui/core/grid/editable_grid'
import ActionToolbar from '/src/ui/core/toolbar/action_toolbar'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'
import useRequestQueryByDiscipline from '/src/hooks/request_query_by_discipline'

// eslint-disable-next-line max-lines-per-function
export default function EditableGridWrapper({
  dataSource,
  columns,
  inEdit,
  onCancel,
  onAction,
  children,
  model,
  allowCreate,
  allowDelete,
  allowDuplicate,
  onCreateNewItem,
  shouldAllowCell,
  isRowEditable,
  tabTemplate,
  filterStepsModalOpts
}) {
  const [numChanges, setNumChanges] = useState(0)
  const [hasChanges, setHasChanges] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [disableSave, setDisableSave] = useState(false)
  const [showDiscardConfirmation, renderDiscardConfirmation] = useConfirmationModal()

  const templateId = useTemplateId(model)
  const { columnsUpdated } = useRequestQueryByDiscipline(columns, model.paramName, templateId)

  useBeforeUnload(inEdit && hasChanges)

  useEffect(() => {
    if (!inEdit) return

    setNumChanges(0)
    setHasChanges(false)
  }, [inEdit])

  const onCancelBulkEditing = () => {
    hasChanges
      ? showDiscardConfirmation({
          title: I18n.t('discard_modal.title'),
          description: I18n.t('discard_modal.description'),
          actionButtonText: I18n.t('discard_modal.ok'),
          cancelButtonText: I18n.t('discard_modal.cancel'),
          buttonColor: '#B33939',
          onSuccess: () => onCancel
        })
      : onCancel()
  }

  const onSaveBulkEditing = () => {
    onAction()
    dispatch(BusEvents.RELOAD_GRID)
  }

  const onDataSourceUpdated = useCallback((numItemsChanged) => {
    setNumChanges(numItemsChanged)
    setHasChanges(numItemsChanged > 0)
  }, [])

  useBus(BusEvents.ENABLE_SAVE_EDITABLE_GRID, () => {
    setLoadingSave(false)
    setDisableSave(false)
  }, [])

  useBus(BusEvents.DISABLE_SAVE_EDITABLE_GRID, ({ payload = 'loading' }) => {
    if (payload === 'loading') setLoadingSave(true)
    if (payload === 'disable') setDisableSave(true)
  }, [])

  return (
    <React.Fragment>
      <div className={inEdit ? 'hide-grid' : 'display-grid'}>{children}</div>
      {inEdit && (
        <div className="display-grid" data-testid="editable-grid-wrapper">
          {renderDiscardConfirmation()}
          <Prompt when={inEdit && hasChanges} message={I18n.t('discard_modal.description')} />
          <EditableGridLoader
            isLoading={isBlank(templateId)}
            opts={{
              gridTitle: model.name,
              itemsQuantity: dataSource.data.length,
              isAllowedCreate: allowCreate
            }}
          >
            <EditableGrid
              model={model}
              templateId={templateId}
              dataSource={dataSource.data}
              columns={columnsUpdated}
              shouldAllowCell={shouldAllowCell}
              columnCellFactory={<CellFactory type={model.paramName} />}
              onDataSourceUpdated={onDataSourceUpdated}
              onAction={onSaveBulkEditing}
              onCancel={onCancel}
              allowCreate={allowCreate}
              allowDelete={allowDelete}
              allowDuplicate={allowDuplicate}
              onCreateNewItem={onCreateNewItem}
              isRowEditable={isRowEditable}
              isSaveEnabled={!loadingSave}
              tabTemplate={tabTemplate}
              filterStepsModalOpts={filterStepsModalOpts}
            />
          </EditableGridLoader>
          <ActionToolbar
            description={`${I18n.t('actions.unsaved_changes')} (${numChanges})`}
            cancelText={I18n.t('actions.discard')}
            onCancel={onCancelBulkEditing}
            actionText={I18n.t('actions.save_button_keyboard')}
            onAction={() => dispatch(BusEvents.SAVE_EDITABLE_GRID)}
            loadingAction={loadingSave}
            disableButton={disableSave}
          />
        </div>
      )}
    </React.Fragment>
  )
}

EditableGridWrapper.propTypes = {
  children: PropTypes.node,
  dataSource: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.object),
  inEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  allowCreate: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowDuplicate: PropTypes.bool,
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onCreateNewItem: PropTypes.func,
  shouldAllowCell: PropTypes.func,
  isRowEditable: PropTypes.func,
  tabTemplate: PropTypes.shape({
    discipline_id: PropTypes.number,
    id: PropTypes.number
  }),
  filterStepsModalOpts: PropTypes.oneOfType([PropTypes.object])
}

EditableGridWrapper.defaultProps = {
  children: <React.Fragment />,
  dataSource: { data: [], total: 0 },
  columns: [],
  inEdit: false,
  allowCreate: false,
  allowDelete: true,
  allowDuplicate: true,
  onCancel: () => {},
  onAction: () => {},
  onCreateNewItem: (newItem) => newItem,
  shouldAllowCell: () => true,
  isRowEditable: () => true,
  tabTemplate: null,
  filterStepsModalOpts: {}
}
