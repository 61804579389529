import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import useFormModal from '/src/ui/core/popups/form_modal'
import '/src/static/css/core/layouts/upload_picture_modal.css'

export default function UploadPictureModal({ label, closeUploadPictureModalCallback, dataItem, column, model }) {
  const modalProps = useMemo(() => {
    return {
      modalTitle: I18n.t('rich_text.upload_dialog.window_title'),
      formTitle: label,
      sections: [
        {
          id: 1,
          eav_columns: [
            {
              id: column.id,
              description: column.description,
              type: column.type || 'picture',
              required: false,
              dataItem,
              className: !column?.fixedPicture && 'upload-picture-from-gallery-modal'
            }
          ]
        }
      ],
      buttons: [],
      dataItem: dataItem,
      model,
      width: 48.5,
      height: 80.5,
      sectionable: false,
      closable: true,
      onClose: closeUploadPictureModalCallback
    }
  }, [label, closeUploadPictureModalCallback])

  const [openModal, renderModal] = useFormModal(modalProps)

  useEffect(() => openModal(), [openModal])

  return <div className="upload-picture-from-gallery">{renderModal()}</div>
}

UploadPictureModal.propTypes = {
  label: PropTypes.string.isRequired,
  closeUploadPictureModalCallback: PropTypes.func,
  column: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    fixedPicture: PropTypes.bool,
    description: PropTypes.string
  }).isRequired,
  dataItem: PropTypes.shape({}).isRequired,
  model: PropTypes.shape({}).isRequired
}

UploadPictureModal.defaultProps = {
  closeUploadPictureModalCallback: () => {}
}
