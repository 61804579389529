import React from 'react'
import PropTypes from 'prop-types'
import RequestStatusLabel from '/src/ui/domain/requests/request_status_label.jsx'
import RequestIdCell from '/src/ui/core/grid/request_id_cell'
import RequestModel from '/src/models/request'
import FieldFactory from '/src/ui/core/fields/field_factory'
import SubRequestBadge from './sub_request_badge'
import ColoredProgressBar from '/src/ui/core/progress_bars/colored_progress_bar'

export default function RequestCellFactory({ field, dataItem, value }) {
  const { columns } = new RequestModel()
  const column = columns.find((c) => c.description === field)

  switch (field) {
    case 'request_status':
      return <RequestStatusLabel model="request" dataItem={dataItem} />
    case 'id':
      return <RequestIdCell id={dataItem[field]} request={dataItem} havePinInGrid={true} />
    case 'sub':
      return <SubRequestBadge show={dataItem.parent_id != null} />
    case 'progress':
      return <ColoredProgressBar progress={dataItem?.actual_progress ?? 0} />
    default:
      return <FieldFactory value={value || dataItem[field]} type={column ? column.type : 'text'} />
  }
}

RequestCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.shape({
    parent_id: PropTypes.number,
    actual_progress: PropTypes.number
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.shape()])
}

RequestCellFactory.defaultProps = {
  field: '',
  dataItem: {},
  value: undefined
}
