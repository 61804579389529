import { useEffect } from 'react'
import useFieldSettings from '/src/hooks/field_settings'
import useFormulasServices from '/src/hooks/formulas_services'
import useSetupFormulas from '/src/ui/core/forms/setup_formulas'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function useFormulasAtGrid(templateId, model) {
  const fieldSettings = useFieldSettings(templateId, ['hide_on_grid'])
  const formulasServices = useFormulasServices(templateId, true)
  const [formulas, formulasControlFields, loadingFormulas, treatFormulaFields] = useSetupFormulas(
    templateId,
    model.paramName,
    model.formulas
  )

  useEffect(() => {
    if (loadingFormulas) dispatch(BusEvents.DISABLE_SAVE_EDITABLE_GRID)
    else if (!formulas && !formulasControlFields) dispatch(BusEvents.ENABLE_SAVE_EDITABLE_GRID)
  }, [loadingFormulas, formulas, formulasControlFields])

  return {
    fieldSettings,
    formulasServices,
    formulas,
    formulasControlFields,
    loadingFormulas,
    treatFormulaFields
  }
}
