/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { GridColumn as Column } from '@progress/kendo-react-grid'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFieldSettings from '/src/hooks/field_settings'
import useFormulasServices from '/src/hooks/formulas_services'
import I18n from '/src/utils/translations'
import usePaging from '/src/ui/core/grid/paging_hook'
import useGridFilters from '/src/ui/core/grid/filtering/grid_filters_hook'
import useSorting from '/src/ui/core/grid/sorting_hook'
import useRequestsPinned from '/src/hooks/requests_pinned'
import useRowsSelection from '/src/ui/core/grid/rows_selection_hook'
import CellContentLoader from '/src/ui/core/grid/cell_content_loader'
import CustomizeCell from '/src/ui/core/grid/customize_cell'
import ColumnFooterTitle from '/src/ui/core/grid/column_footer_title'
import ColumnFooterCell from '/src/ui/core/grid/column_footer_cell'
import SimpleGridHeader from '/src/ui/core/grid/simple_grid_header'
import SimpleGridLabels from '/src/ui/core/grid/simple_grid_labels'
import SimpleGridContent from '/src/ui/core/grid/simple_grid_content'
import GridHeaderCell from '/src/ui/core/grid/editable/grid_header_cell'
import { ColumnMenu } from '/src/ui/core/grid/column_menu'
import GridRowContextMenu from '/src/ui/core/grid/grid_row_context_menu'
import GridRowPinIcon from './grid_row_pin_icon'
import useFetchGrid from '/src/ui/core/grid/fetch_grid'
import useGridSettings from '/src/ui/core/grid/grid_settings'
import { getGridPageSize, setGridPageSize } from '/src/utils/store/grid_page_size'
import useColumnOrder from '/src/ui/core/grid/column_order'
import SimpleGridPDFExport from '/src/ui/core/grid/pdf_print/simple_grid_pdf_export'
import PrintListPDFExport from '/src/ui/core/grid/pdf_print/print_list_pdf_export'
import { isFormulaServiceHidden } from '/src/models/concerns/formula'
import useCallbackRef from '/src/hooks/callback_ref'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function SimpleGrid({
  model,
  pageSize,
  filter,
  sortable,
  sort,
  columnCellFactory,
  templateId,
  loadFlexColumns,
  contextMenuItems,
  gridTitle,
  icons,
  labels,
  selectedItem,
  selecting,
  onRowClick,
  page,
  selectFiltering,
  onFilterUpdate,
  additionalEntities,
  onRowRender,
  onDataSource,
  onGridColumns,
  animatePopup,
  clearAllFiltersCallback,
  skipClearRequest,
  clearAllFiltersVisibility,
  isSubGrid,
  havePinInGrid
}) {
  const [subproject] = useStore('subproject')

  const [formattedGridColumns, setFormattedGridColumns] = useState()
  const [dataSource, setDataSource] = useState({ data: [], total: 0 })
  const [selectedId, setSelectedId] = useState(0)
  const [actualPageSize, setActualPageSize] = useState(getGridPageSize() || pageSize)
  const scrollPosition = useRef()
  const onGridDataChangeRef = useCallbackRef(onDataSource)

  const {
    savingPreferencesStatus,
    getColumnsWithUpdatedSettings,
    setHiddenColumns,
    setColumnWidth,
    setColumnsOrder,
    getSorting,
    setSorting
  } = useGridSettings({
    templateId,
    foreignEntities: model.dropForeignColumns
  })

  const [status, setStatus] = useState('loading')
  const { paging, onPageChange } = usePaging(actualPageSize, page)
  const gridPage = page || Math.ceil((paging.skip + paging.pageSize) / paging.pageSize)

  const { clearRequests } = useRequestsPinned()

  const filterProps = {
    defaultFilters: filter,
    onChange: onFilterUpdate,
    dataSourceTotal: dataSource.total,
    templateId
  }

  const {
    kendoGridFilters,
    queryFilters,
    onFilterChange,
    hasColumnFilters,
    clearColumnFilters,
    clearAllColumnFilters,
    clearDeletedColumnFilters: onColumnsFetch
  } = useGridFilters(filterProps)

  useBus(
    BusEvents.CLEAN_GRID_FILTERS,
    ({ paramName, skipClearRequests }) => {
      if (paramName !== model.paramName) return
      if (!skipClearRequests) clearRequests()
      clearAllColumnFilters()
    },
    [clearAllColumnFilters, clearRequests]
  )

  const initialSorting = getSorting() || sort
  const { sorting, onSortChange } = useSorting(initialSorting, setSorting)
  const [columnOrder, onColumnReorder] = useColumnOrder()
  const { onSelectChange, getHeaderSelectionColumn } = useRowsSelection(setDataSource, model?.paramName)

  const useGridProps = {
    model,
    paging,
    queryFilters,
    sorting,
    templateId,
    onGridDataChange: onDataSource,
    flexible: loadFlexColumns,
    additionalEntities,
    onColumnsFetch
  }
  const { loading, responseData, flexibleColumnsData, errors, read } = useFetchGrid(useGridProps)
  const [gridColumns, setGridColumns] = useState(
    getColumnsWithUpdatedSettings([...model.columns, ...flexibleColumnsData])
  )

  const formulasServices = useFormulasServices(templateId, true)
  const fieldSettings = useFieldSettings(templateId, ['hide_on_grid'])

  const gridWrapper = useRef(null)

  // Removes the row select when the side panel is closed
  useBus(
    BusEvents.SIDE_PANEL_CLOSED,
    () => {
      setSelectedId(0)
    },
    [setSelectedId]
  )

  // Read data again when BusEvent.RELOAD_GRID is triggered
  useBus(BusEvents.RELOAD_GRID, () => read(), [read])

  // sets data source as soon as the grid data is returned from the server
  useEffect(() => {
    if (loading && status !== 'loading' && responseData.data.length === 0) {
      setStatus('empty-loading')
      return
    }

    if (loading || loading === undefined || errors) return
    setDataSource(responseData)

    if (hasColumnFilters() || responseData.data.length > 0) {
      setStatus('loaded')
      return
    }

    if (!templateId) {
      setStatus('noTemplate')
      return
    }

    setStatus('empty')
  }, [loading, responseData, errors, status, hasColumnFilters, templateId])

  useEffect(() => {
    if (loading) return
    if (onGridDataChangeRef.current) onGridDataChangeRef.current({ ...dataSource, loading })
  }, [loading, dataSource, onGridDataChangeRef])

  // embeds flexible column on grid columns array as soon as the
  // flexible columns data is returned from the server
  useEffect(() => {
    if (loadFlexColumns && flexibleColumnsData.length > 0) {
      setGridColumns(getColumnsWithUpdatedSettings([...model.columns, ...flexibleColumnsData]))
    }
  }, [flexibleColumnsData])

  // Recover scroll position
  useEffect(() => {
    if (selectedId) return

    const contentDiv = document.getElementsByClassName('k-grid-content')[0]

    if (contentDiv && contentDiv.scrollTo && scrollPosition.current)
      contentDiv.scrollTo(scrollPosition.current[0], scrollPosition.current[1])
  }, [selectedId])

  // creates columns components based on the grid data and flexible columns
  useEffect(() => {
    if (!gridColumns) return
    let formattedColumns = []
    formattedColumns = addActionsColumn(formattedColumns)
    formattedColumns = addModelColumns(formattedColumns)
    formattedColumns = addPinColumn(formattedColumns)
    formattedColumns = addSelectionColumns(formattedColumns)
    formattedColumns = addFooterColumns(formattedColumns)

    setFormattedGridColumns(formattedColumns)
    if (onGridColumns) onGridColumns(gridColumns)
  }, [gridColumns, dataSource, loading, formulasServices, onRowClick])

  useEffect(() => {
    setSelectedId(selectedItem ? selectedItem.id : undefined)
  }, [selectedItem])

  const addActionsColumn = (formattedColumns) => {
    if (!contextMenuItems) return formattedColumns

    const actionsColumn = [
      <Column
        key="action"
        orderIndex={-2}
        field=""
        width={60}
        sortable={false}
        filterable={false}
        reorderable={false}
        resizable={false}
      />
    ]

    if (['progress', 'progress_service'].includes(model.paramName)) {
      actionsColumn.push(
        <Column
          key="action"
          orderIndex={-2}
          field=""
          width={60}
          sortable={false}
          filterable={false}
          reorderable={false}
          resizable={false}
        />
      )
    }

    formattedColumns.splice(0, 0, [...actionsColumn])
    return formattedColumns
  }

  const saveScrollPosition = () => {
    const top = document.getElementsByClassName('k-grid-content')[0].scrollTop
    const left = document.getElementsByClassName('k-grid-content')[0].scrollLeft
    scrollPosition.current = [left, top]
  }

  const onFilterChangeSaveScrollPosition = (e) => {
    saveScrollPosition()
    onFilterChange(e, gridColumns)
  }

  const resetScrollPosition = () => {
    const topContentDiv = document.getElementsByClassName('k-grid-content')[0]
    const subContentDiv = document.getElementsByClassName('k-grid-content')[1]

    if (topContentDiv && topContentDiv.scrollTo) topContentDiv.scrollTo(0, 0)
    if (subContentDiv && subContentDiv.scrollTo) subContentDiv.scrollTo(0, 0)
  }

  const onColumnsSubmit = (columnsState) => {
    setHiddenColumns(columnsState)

    const updatedColumns = getColumnsWithUpdatedSettings(columnsState)
    setGridColumns(updatedColumns)
  }

  const onColumnResize = (event) => {
    const { end, index, newWidth } = event
    if (!end) return

    const orderedColumns = event.columns.sort((a, b) => {
      return a.orderIndex - b.orderIndex
    })
    const columnField = orderedColumns[index].field

    const column = gridColumns.find((col) => col.field === columnField || col.description === columnField)

    setColumnWidth(column.description, newWidth)
    setGridColumns((old) => getColumnsWithUpdatedSettings(old))
  }

  const onChangePageSize = (size) => {
    setGridPageSize(size)
    setActualPageSize(size)
    resetScrollPosition()
  }

  const isColumnHidden = (column) => {
    if (column.hideOnSubGrid && isSubGrid) return true

    const setting = fieldSettings[column.foreignAttribute] || fieldSettings[column.description]
    const columnVisible = formulasServices ? formulasServices[`${column.description}_visible`] : true
    const notVisible = columnVisible !== undefined ? !columnVisible : false

    const hideOnSubProject = column.hideOnSubProject && column.hideOnSubProject(subproject)
    const hideScaffolding = column.description === 'tag_number' && !subproject.show_scaffolding

    return (
      notVisible ||
      hideScaffolding ||
      hideOnSubProject ||
      column.hideOnGrid ||
      (setting && setting.hide_on_grid) ||
      isFormulaServiceHidden(column.description, formulasServices)
    )
  }

  const addModelColumns = (formattedColumns) => {
    const pickColumns = []
    gridColumns.forEach((column, index) => {
      if (isColumnHidden(column)) return

      pickColumns.push(buildColumnComponent(column, index))
    })

    formattedColumns.push(pickColumns)
    return formattedColumns
  }

  const addSelectionColumns = (formattedColumns) => {
    if (!selecting) return formattedColumns

    const i = contextMenuItems ? 1 : 0
    const selectionColumn = getHeaderSelectionColumn(dataSource)

    formattedColumns.splice(i, 0, selectionColumn)
    return formattedColumns
  }

  const addFooterColumns = (formattedColumns) => {
    if (!model.footer) return formattedColumns

    const i = contextMenuItems ? 1 : 0
    const selectedCount = dataSource.data.filter((dataItem) => dataItem.row_selected).length
    const totalColumn = (
      <Column
        key="selectedCount"
        field=""
        width={1}
        filterable={false}
        reorderable={false}
        resizable={false}
        sortable={selecting}
        footerCell={() => <ColumnFooterTitle showTotals={model.showTotals} selected={selectedCount} />}
      />
    )

    formattedColumns.splice(i, 0, totalColumn)
    return formattedColumns
  }

  const addPinColumn = (formattedColumns) => {
    if (!havePinInGrid) return formattedColumns;
    const pinColumn = (
      <Column
        key="pinColumn"
        width={60}
        filterable={false}
        reorderable={false}
        resizable={false}
        sortable={false}
        cell={(props) => <GridRowPinIcon dataItem={props.dataItem} id={props.dataItem.id} />}
      />
    );
    formattedColumns.splice(1, 0, pinColumn);
    return formattedColumns;
  }

  const onGridRowClick = (e) => {
    if (loading) return

    if (!selectedId) saveScrollPosition()

    if (onRowClick) onRowClick(e)
    else setSelectedId((prevSelectedId) => (prevSelectedId === e.dataItem.id ? undefined : e.dataItem.id))
  }

  const customizedCell = (column) => {
    const cellContendLoader = () => <CellContentLoader />
    const customizeCell = (props) => (
      <CustomizeCell
        cell={props}
        column={column}
        columnCellFactory={columnCellFactory}
        columns={gridColumns}
        onClick={onGridRowClick}
      />
    )

    return loading ? cellContendLoader : customizeCell
  }

  const gridColumnOrderOffset = useMemo(() => model.gridColumnOrderOffset || 0, [model])

  const buildColumnComponent = (column, index) => {
    const field = column.field || column.description

    return (
      !column.hide && (
        <Column
          key={column.description}
          field={field}
          title={column.title}
          sortable={column.sortable}
          orderIndex={(column.orderIndex || index) + gridColumnOrderOffset}
          width={column.width}
          cell={customizedCell(column)}
          filterable={column.filterable}
          columnMenu={
            column.filterable === false
              ? undefined
              : (props) => (
                <ColumnMenu
                  {...props}
                  flexible={column.flexible}
                  columns={gridColumns}
                  onColumnsSubmit={(columnsState) => onColumnsSubmit(columnsState)}
                />
              )
          }
          footerCell={
            column.footer
              ? () => <ColumnFooterCell column={column.description} filtered={selecting} data={dataSource.data} />
              : null
          }
          headerCell={({ children, onClick }) => (
            <GridHeaderCell title={column.title || column.description} column={column} onClick={onClick}>
              {children}
            </GridHeaderCell>
          )}
        />
      )
    )
  }

  const rowRender = (trElement, dataItem) => {
    let props
    if (onRowRender) props = onRowRender(trElement, dataItem, onGridRowClick)
    const trProps = { ...trElement.props, ...props }

    if (contextMenuItems) {
      const gridRowMenu = (
        <GridRowContextMenu
          key={`${dataItem.dataItem.id}-context-menu`}
          dataItem={dataItem.dataItem}
          data={dataSource.data}
          items={contextMenuItems}
        />
      )

      trElement.props.children.splice(0, 1, gridRowMenu)
    }

    if (selectedId === dataItem.dataItem.id) {
      trProps.className = `${trProps.className} k-state-selected`
    }

    return React.cloneElement(trElement, { ...trProps }, trElement.props.children)
  }

  return (
    <div className="entity-grid-wrapper" ref={gridWrapper}>
      <SimpleGridHeader
        key="gridHeader"
        page={gridPage}
        modelName={model?.paramName}
        take={paging.pageSize}
        total={Math.ceil(dataSource.total / paging.pageSize)}
        loading={loading}
        gridTitle={gridTitle || I18n.t('grid.all')}
        icons={icons}
        itemsQuantity={dataSource.total}
        onPageChange={(event, offset) => {
          onPageChange(event, offset)
          resetScrollPosition()
        }}
        onChangePageSize={onChangePageSize}
        savingStatus={savingPreferencesStatus}
        templateId={templateId}
        model={model}
      >
        <SimpleGridLabels
          key="gridLabels"
          gridColumns={gridColumns}
          labels={labels}
          kendoGridFilters={kendoGridFilters}
          clearColumnFilters={clearColumnFilters}
          clearAllFiltersCallback={clearAllFiltersCallback}
          modelParamName={model.paramName}
          skipClearRequest={skipClearRequest}
          clearAllFiltersVisibility={clearAllFiltersVisibility}
        />
      </SimpleGridHeader>
      <SimpleGridContent
        status={status}
        model={model}
        animatePopup={animatePopup}
        gridWrapper={gridWrapper}
        selectedItem={selectedItem}
        selectedId={selectedId}
        selectFiltering={selectFiltering}
        dataSource={dataSource}
        gridColumns={gridColumns}
        formattedGridColumns={formattedGridColumns}
        rowRender={rowRender}
        kendoGridFilters={kendoGridFilters}
        onFilterChange={(e) => onFilterChangeSaveScrollPosition(e, gridColumns)}
        sortable={sortable}
        sorting={sorting}
        onColumnReorder={(e) => {
          const reorderedColumns = onColumnReorder(e, gridColumns)
          setColumnsOrder(reorderedColumns)
          setGridColumns(reorderedColumns)
        }}
        onSortChange={(e) => onSortChange(e, gridColumns)}
        onColumnResize={onColumnResize}
        onGridRowClick={onGridRowClick}
        onSelectionChange={(e) => onSelectChange(e, dataSource)}
      />
      <SimpleGridPDFExport model={model} gridData={dataSource.data} cellFormat={customizedCell} />
      <PrintListPDFExport modelName={model?.name} gridPage={gridPage} />
    </div>
  )
}

SimpleGrid.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageSize: PropTypes.number,
  filter: PropTypes.arrayOf(PropTypes.object),
  sortable: PropTypes.bool,
  sort: PropTypes.arrayOf(PropTypes.object),
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  selecting: PropTypes.bool,
  loadFlexColumns: PropTypes.bool,
  columnCellFactory: PropTypes.element,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ),
  gridTitle: PropTypes.string,
  templateId: PropTypes.number,
  icons: PropTypes.oneOfType([PropTypes.array]),
  labels: PropTypes.oneOfType([PropTypes.array]),
  onRowClick: PropTypes.func,
  onRowRender: PropTypes.func,
  onDataSource: PropTypes.func,
  onGridColumns: PropTypes.func,
  selectFiltering: PropTypes.bool,
  onFilterUpdate: PropTypes.func,
  additionalEntities: PropTypes.arrayOf(
    PropTypes.shape({
      foreignKey: PropTypes.string.isRequired,
      query: PropTypes.string
    })
  ),
  animatePopup: PropTypes.bool,
  clearAllFiltersCallback: PropTypes.func,
  skipClearRequest: PropTypes.bool,
  clearAllFiltersVisibility: PropTypes.bool,
  isSubGrid: PropTypes.bool,
}

SimpleGrid.defaultProps = {
  pageSize: 30,
  filter: [],
  sortable: true,
  selectedItem: undefined,
  selecting: false,
  onFilterUpdate: undefined,
  loadFlexColumns: false,
  sort: [],
  columnCellFactory: null,
  contextMenuItems: null,
  gridTitle: undefined,
  templateId: undefined,
  icons: ['more'],
  labels: [],
  onRowClick: undefined,
  onRowRender: undefined,
  onDataSource: undefined,
  onGridColumns: undefined,
  selectFiltering: true,
  additionalEntities: [],
  animatePopup: true,
  clearAllFiltersCallback: () => { },
  skipClearRequest: false,
  clearAllFiltersVisibility: true,
  isSubGrid: false,
  havePinInGrid: false
}
