import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useStore } from 'react-context-hook'
import useFetch from '/src/hooks/api/fetch'
import { filterFormulaColumns } from '/src/models/concerns/eav_column'
import calculateFormulas from '/src/ui/core/grid/editable/formulas_calculation'
import {
  additionalFieldsToChangedItem,
  applyFormulaResults,
  applyItemChange,
  shouldReloadGrid
} from '/src/ui/core/grid/editable/data_appliers'
import { byString, isEmpty, objectEquals } from '/src/utils/object'
import { indexify } from '/src/utils/array'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { isBlank } from '/src/utils/boolean_refinements'

const convertFormulasIds = (formulaArray = [], itemId) =>
  formulaArray.map((formula) => ({ ...formula, id: `${itemId}_${formula.id}` }))

const isUnavailableParent = (editionEvent, parents, parentModels) => {
  if (!parentModels || parentModels.length === 0) return false

  const { field, value } = editionEvent
  const fieldSplitted = field.split('_id')

  if (fieldSplitted.length > 2 || !parentModels.includes(fieldSplitted[0])) return false

  const parentId = fieldSplitted.length === 2 ? value : value.id
  const parent = byString(parents, `${fieldSplitted[0]}.${parentId}`)
  return isBlank(parent)
}

export default function useBulkWatch({
  gridState,
  onEditCallback,
  formulas,
  formulasControlFields,
  parents,
  parentModels,
  includeOnFormula,
  treatFormulaFields,
  getDataItemKey
}) {
  const [variableTypes] = useStore('variable_types')
  const { fetch } = useFetch()

  const mountFormulasFetched = useRef(false)
  const editionTimer = useRef()
  const numCalculations = useRef(0)
  const prevParents = useRef(parents)

  const formulaColumns = useMemo(() => filterFormulaColumns(gridState.columns), [gridState.columns])

  const formulasControlFieldsById = useMemo(() => indexify(formulasControlFields || [], 'id'), [formulasControlFields])

  const getDataItemId = useCallback((dataItem) => dataItem[getDataItemKey(dataItem)], [getDataItemKey])

  const dispatchCalculationsChange = useCallback((calculationsStarted) => {
    numCalculations.current += calculationsStarted ? 1 : -1
    if (numCalculations.current === 0) dispatch(BusEvents.ENABLE_SAVE_EDITABLE_GRID)
    else dispatch(BusEvents.DISABLE_SAVE_EDITABLE_GRID)
  }, [])

  const dispatchFormulasCalculations = useCallback(
    (formulasByRow, dataItems) => {
      const { columns } = gridState

      dispatchCalculationsChange(true)

      calculateFormulas({
        fetch,
        formulasByRow,
        dataItems,
        parents,
        parentModels,
        columns,
        includeOnFormula,
        variableTypes,
        treatFormulaFields,
        getDataItemId
      })
        .then(([newDataItems, formulaResults]) => {
          applyFormulaResults({
            newDataItems,
            formulaResults,
            gridState: {
              ...gridState,
              formulaColumns,
              formulasControlFieldsById
            },
            getDataItemId
          })
        })
        .finally(() => dispatchCalculationsChange(false))
    },
    [
      fetch,
      formulaColumns,
      gridState,
      parents,
      includeOnFormula,
      variableTypes,
      treatFormulaFields,
      getDataItemId,
      formulasControlFieldsById,
      dispatchCalculationsChange
    ]
  )

  const calculateFormulasFromDataSource = useCallback(
    (dataSource) => {
      const dataSourceToCalculate = [...dataSource]

      const formulasByRow = {}

      dataSourceToCalculate.forEach((dataItem) => {
        const itemId = getDataItemId(dataItem)
        formulasByRow[itemId] = [
          ...convertFormulasIds(formulas, itemId),
          ...convertFormulasIds(formulasControlFields, itemId)
        ]
      })
      if (!isEmpty(formulasByRow)) dispatchFormulasCalculations(formulasByRow, dataSourceToCalculate)
      else if (dataSourceToCalculate.length === 0) dispatch(BusEvents.ENABLE_SAVE_EDITABLE_GRID)
      else dispatch(BusEvents.DISABLE_SAVE_EDITABLE_GRID)
    },
    [getDataItemId, dispatchFormulasCalculations, formulas, formulasControlFields]
  )

  useEffect(() => {
    if (
      mountFormulasFetched.current ||
      !variableTypes ||
      !formulas ||
      !parents ||
      !formulasControlFields ||
      (isEmpty(formulas) && isEmpty(formulasControlFields))
    )
      return

    calculateFormulasFromDataSource(gridState.dataSource)

    mountFormulasFetched.current = true
    prevParents.current = parents
  }, [gridState.dataSource, formulas, formulasControlFields, parents, variableTypes, calculateFormulasFromDataSource])

  useEffect(() => {
    if (
      !mountFormulasFetched.current ||
      !variableTypes ||
      !formulas ||
      !parents ||
      !formulasControlFields ||
      (isEmpty(formulas) && isEmpty(formulasControlFields))
    )
      return
    if (objectEquals(prevParents.current, parents)) return

    calculateFormulasFromDataSource(gridState.newDataSource)

    prevParents.current = parents
  }, [
    gridState.newDataSource,
    formulas,
    formulasControlFields,
    parents,
    variableTypes,
    calculateFormulasFromDataSource
  ])

  useEffect(() => {
    return () => clearTimeout(editionTimer.current)
  }, [])

  const onItemUpdate = (newDataItem, shouldCalculateFormulas = true) => {
    const hasFormulasToCalculate =
      (formulas && !isEmpty(formulas)) || (formulasControlFields && !isEmpty(formulasControlFields))

    if (newDataItem && hasFormulasToCalculate && shouldCalculateFormulas) {
      const itemId = getDataItemId(newDataItem)
      dispatchFormulasCalculations(
        {
          [itemId]: [...convertFormulasIds(formulas, itemId), ...convertFormulasIds(formulasControlFields, itemId)]
        },
        [newDataItem]
      )
    } else {
      dispatch(BusEvents.ENABLE_SAVE_EDITABLE_GRID)
    }
  }

  const itemChange = (event) => {
    const newData = applyItemChange(event, gridState, getDataItemKey)

    if (!newData) return

    onEditCallback(newData)

    clearTimeout(editionTimer.current)
    editionTimer.current = setTimeout(() => {
      if (shouldReloadGrid(event, gridState)) {
        dispatch(BusEvents.DISABLE_SAVE_EDITABLE_GRID)
      }

      onItemUpdate({
        ...event.dataItem,
        ...additionalFieldsToChangedItem(event.field, event.dataItem),
      }, !isUnavailableParent(event, parents, parentModels))
    }, 1000)
  }

  return { itemChange, onItemUpdate }
}
