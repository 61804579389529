
export const estimatePDFName = (estimates) => {
  if (estimates.length === 1) {
    const request = estimates[0]

    if (!request.comments) return `${request.reason} - ${request.discipline}`.replace(/\./g, "")

    return `${request.reason} - ${request.discipline} - ${request.comments}`.replace(/\./g, "")
  }

  if (estimates.length > 1) {
    const reasonSet = new Set(estimates.map((request) => request.reason))
    const hasComments = estimates.some((request) => request.comments)
    const commentSet = new Set(estimates.map((request) => request.comments))
    
    if (reasonSet.size === 1 && (!hasComments || commentSet.size === 1)) {
      return `${[...reasonSet][0]}${hasComments ? `_${[...commentSet][0]}` : ''} [${estimates.length}]`
    }
    
    return `${[...reasonSet].join('_')} [${estimates.length}]`
  }
  
  return 'export'
}
