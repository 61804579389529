import React from 'react'
import PropTypes from 'prop-types'
import CancelButton from '/src/ui/core/buttons/cancel_button'
import ActionButton from '/src/ui/core/buttons/action_button'
import '/src/static/css/core/toolbar/action_toolbar.css'

export default function ActionToolbar({
  children,
  className,
  description,
  cancelText,
  onCancel,
  actionText,
  onAction,
  actionIcon,
  loadingAction,
  actionClassName,
  disableButton
}) {
  return (
    <div className={className || 'action-toolbar'}>
      <div>
        <CancelButton title={cancelText} onClick={onCancel} />
      </div>
      {description && <div className="action-toolbar-description">{description}</div>}
      <div className="action-buttons">
        {children}
        <ActionButton
          icon={actionIcon}
          title={actionText}
          className={actionClassName}
          onClick={onAction}
          loading={loadingAction}
          disableButton={disableButton}
        />
      </div>
    </div>
  )
}

ActionToolbar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  actionIcon: PropTypes.element,
  loadingAction: PropTypes.bool,
  actionClassName: PropTypes.string,
  disableButton: PropTypes.bool,
}

ActionToolbar.defaultProps = {
  children: undefined,
  className: undefined,
  description: undefined,
  onCancel: () => {},
  onAction: () => {},
  actionIcon: undefined,
  loadingAction: false,
  actionClassName: undefined,
  disableButton: false
}
