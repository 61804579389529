import React from 'react'
import classnames from 'classnames'
import { useStore } from 'react-context-hook'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { ReactComponent as FilterPinRequests } from '/public/static/svg/filter-pin-requests'
import I18n from '/src/utils/translations'

export default function FilterPinnedRequests() {
  const [subprojectFilters, setSubprojectFilters] = useStore('subproject_filters')
  const isFilterActive = subprojectFilters?.is_filter_active

  const handleTogglePinnedRequests = () => {
    const newSubprojectFilters = { ...subprojectFilters, is_filter_active: !isFilterActive }
    setSubprojectFilters(newSubprojectFilters)
  }

  const buttonClasses = classnames('btn-icon grid-header-icon', {
    'selected-filter': isFilterActive
  })

  return (
    <CustomTooltip position="bottom" parentTitle>
      <button
        id="filter-pin-request"
        type="button"
        title={isFilterActive ? I18n.t('actions.show_all_work_orders') : I18n.t('actions.filter_pinned_work_orders')}
        data-testid="filter-pin-request"
        className={buttonClasses}
        onClick={handleTogglePinnedRequests}
      >
        <FilterPinRequests />
      </button>
    </CustomTooltip>
  )
}
