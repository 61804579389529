import React from 'react'
import PropTypes from 'prop-types'
import useRequestsPinned from '/src/hooks/requests_pinned'
import { isEmpty } from '/src/utils/object'
import { useStore } from 'react-context-hook'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import I18n from '/src/utils/translations'

export default function RequestIdCell({ id, request, showStatus, havePinInGrid }) {
  const { selectedRequests, setPinnedRequests } = useRequestsPinned()
  const [statuses] = useStore(`request_statuses`)
  const requestStatus = statuses && statuses.length !== 0 && statuses[request?.request_status_id]

  if (!id) return null

  return (
    <div style={{ display: showStatus ? 'flex' : '' }}>
      {showStatus && (
        <CustomTooltip key={id}>
          <div
            className={`dpmsid-workflow-status ${requestStatus?.i18n_id.replaceAll('_', '-')}`}
            title={I18n.t(`requests.statuses.${requestStatus?.i18n_id}`)}
          >
            <span className="status-circle" data-testid="status-circle" />
          </div>
        </CustomTooltip>
      )}
      {havePinInGrid ? (
        <span className="dpmsid-cell-factory no-underline">{id}</span>
      ) : (
        <button
          className="dpmsid-cell-factory"
          onClick={() => setPinnedRequests([{ ...request, id }])}
          type="button"
          disabled={!isEmpty(selectedRequests)}
        >
          {id}
        </button>
      )}

    </div>
  )
}

RequestIdCell.propTypes = {
  id: PropTypes.number,
  request: PropTypes.object,
  showStatus: PropTypes.bool
}

RequestIdCell.defaultProps = {
  id: undefined,
  request: {},
  showStatus: false,
  havePinInGrid: false
}
