// eslint-disable-next-line
import { store } from 'react-context-hook'
import { scaffoldingTotalUnit } from '/src/ui/domain/scaffoldings/scaffolding_part_total_column'
import I18n from '/src/utils/translations'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { includeDateColumns } from '/src/models/concerns/datetime_columns'

export const measurementDimension = (value) => {
  if (value.length && value.width && value.height) return 'm³'
  if (value.length && value.width) return 'm²'
  return 'm'
}

export default class ScaffoldingPartModel {
  constructor() {
    this.paramName = ScaffoldingPartModel.paramName
  }

  static paramName = 'scaffolding_part'

  name = I18n.t('scaffolding_parts.title')

  singularName = I18n.t('scaffolding_parts.singular')

  templateType = 'scaffolding_part'

  route = 'scaffolding_parts'

  flexible = false

  templateId = -2

  initialColumns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('scaffolding_parts.id'),
      hideOnForm: true,
      hideOnGrid: true,
      hideOnSidePanel: true,
      width: 120,
      type: 'integer',
      section: 1,
      visible_on_web: true
    },
    {
      id: 2,
      description: 'scaffolding_id',
      title: I18n.t('scaffoldings.id'),
      hideOnForm: true,
      hideOnGrid: true,
      hideOnSidePanel: true,
      type: 'integer'
    },
    {
      id: 3,
      description: 'number',
      title: '#',
      hideOnForm: true,
      width: 100,
      type: 'string'
    },
    {
      id: 4,
      description: 'scaffolding_type_id',
      title: I18n.t('scaffolding_parts.scaffolding_type_id'),
      required: true,
      width: 150,
      foreignKey: 'scaffolding_types',
      foreignAttribute: 'scaffolding_type_id',
      textDisplayFields: ['description'],
      textField: 'description',
      type: 'drop',
      section: 1,
      visible_on_web: true,
      sidePanelColumnFactory: true,
      icon: function fn(scaffoldingTypeId) {
        switch (scaffoldingTypeId) {
          case 'tent_frame':
            return 'square'
          case 'railing':
            return 'vertical_shades_closed'
          case 'scaffolding_net':
            return 'grid_4x4'
          case 'system_covering':
          case 'covering':
            return 'picture_in_picture_large'
          default:
            return 'deployed_code'
        }
      }.bind(this),
      fieldFormatter: function fn(dataItem) {
        const scaffoldingType = dataItem?.scaffolding_type
        const translationKey = scaffoldingType && scaffoldingType.i18n_id
        if (!translationKey) return ''
        return I18n.t(`scaffolding_parts.scaffolding_types.${translationKey}`)
      },
      orderOnForm: 1
    },
    {
      id: 5,
      description: 'erect_method',
      title: I18n.t('scaffolding_parts.erect_method'),
      type: 'drop',
      width: 200,
      section: 1,
      required: true,
      visible_on_web: true,
      sidePanelColumnFactory: true,
      fieldFormatter: function fn(dataItem) {
        const { erect_method: erectMethod } = dataItem
        if (!erectMethod) return ''
        return I18n.t(`scaffolding_parts.erect_methods.${erectMethod}`)
      }.bind(this),
      metadata: JSON.stringify([
        { id: 'tt', description: I18n.t('scaffolding_parts.erect_methods.tt') },
        { id: 'os', description: I18n.t('scaffolding_parts.erect_methods.os') },
        { id: 'standard', description: I18n.t('scaffolding_parts.erect_methods.standard') }
      ]),
      default: 'standard',
      orderOnForm: 2
    },
    {
      id: 6,
      description: 'operation_erect',
      title: I18n.t('scaffolding_parts.operation_erect'),
      width: 150,
      type: 'string',
      section: 1,
      visible_on_web: true,
      orderOnForm: 3
    },
    {
      id: 7,
      description: 'operation_dismantle',
      title: I18n.t('scaffolding_parts.operation_dismantle'),
      width: 150,
      type: 'string',
      section: 1,
      visible_on_web: true,
      orderOnForm: 4
    },
    {
      id: 8,
      description: 'total',
      title: I18n.t('scaffolding_parts.total'),
      width: 150,
      type: 'decimal',
      section: 1,
      sidePanelColumnFactory: true,
      editable: false,
      hideOnForm: true,
      allowOnEditableGrid: false,
      read_only: true,
      sortable: false,
      filterable: false,
      fieldFormatter: function fn(dataItem) {
        const project = store.get('project') || {}
        const total = dataItem.total
        const unit = scaffoldingTotalUnit(dataItem)
        return `${formatNumberWithPrecision(total, project)} ${unit}`
      }.bind(this)
    },
    {
      id: 9,
      description: 'actions',
      title: ' ',
      hideOnGrid: true,
      hideOnForm: (dataItem) => {
        return dataItem?.id
      },
      dependency: 'scaffolding_type_id',
      width: 100,
      type: 'multiple_entries',
      hideColumns: function fn(scaffoldingType, columns) {
        const types = store.get('scaffolding_types')
        const typeOfScaffolding = types[scaffoldingType]?.dimension_type || undefined
        if (typeOfScaffolding === 'm3') {
          return columns
        }
        if (typeOfScaffolding === 'm2') {
          return columns.filter((column) => column.description !== 'height')
        }
        if (typeOfScaffolding === 'm') {
          return columns.filter((column) => column.description !== 'height' && column.description !== 'width')
        }

        return []
      },
      columns: [
        {
          id: 1,
          description: 'length',
          title: I18n.t('scaffolding_part_actions.length'),
          type: 'decimal',
          web_form_width: '25'
        },
        {
          id: 2,
          description: 'width',
          title: I18n.t('scaffolding_part_actions.width'),
          type: 'decimal',
          web_form_width: '25'
        },
        {
          id: 3,
          description: 'height',
          title: I18n.t('scaffolding_part_actions.height'),
          type: 'decimal',
          web_form_width: '25'
        },
        {
          id: 4,
          description: 'total',
          title: I18n.t('scaffolding_part_actions.total'),
          type: 'aggregate',
          web_form_width: '25'
        }
      ],
      deletedEntryMessage: function fn(entry) {
        const project = store.get('project') || {}
        const message = I18n.t('scaffolding_parts.action_section.action_removed', {
          total: formatNumberWithPrecision(entry.total, project),
          dimension: measurementDimension(entry)
        })
        return message
      },
      section: 2,
      visible_on_web: true
    }
  ]

  columns = includeDateColumns(this.initialColumns)

  sections = [
    {
      id: 1,
      position: 1,
      title: I18n.t('scaffoldings.sections.general'),
      columns: this.columns.filter((column) => column.section === 1)
    },
    {
      id: 2,
      position: 2,
      title: I18n.t('scaffolding_parts.action_section.title'),
      columns: this.columns.filter((column) => column.section === 2),
      hideSectionOnSidePanel: true
    }
  ]
}
